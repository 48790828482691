$(function ($) {
    let navbar = $('.navbar');

    $('a[href*=\\#]').on('click', function(event){
        let $element = $(this.hash);
        if ($element.length){
            event.preventDefault();
            $('html,body').animate({scrollTop:$element.offset().top - navbar.outerHeight() - 20}, 500);
        }
    });

    $('form[action*=\\#]').on('submit', function(event){
        let url = $(this).attr('action');
        let hash = url.substring(url.indexOf('#'));
        let $element = $(hash);
        if ($element.length){
            event.preventDefault();
            $('html,body').animate({scrollTop:$element.offset().top - navbar.outerHeight() - 20}, 500);
        }
    });

    // Smooth scrolling when the document is loaded and ready
    $(document).ready(function(){
        let $element = $(location.hash);

        if ($element.length) {
            let offset = $element.offset();

            $('html,body').animate({
                scrollTop: offset.top - navbar.outerHeight() - 20
            }, 500);
        }
    });
});



$(function() {
  const inputFileElements = $('input[type="file"]').length;

  $('input[type="file"]').each(function (index) {
    $(this).attr('id', `fileInput${index}`);
    $(this).siblings('label').attr('for', `fileInput${index}`);
  });

  for (let i = 0; i < inputFileElements; i += 1) {
    $(`#fileInput${i}`).on('change', function () {
      let fileName = '';
      let fileWithoutPath = '';
      fileName = $(this).val();
      fileWithoutPath = fileName.substring(12, fileName.length); // hide c/fakepath
      if($(this).data('append') == true) {
        $('.chat-add-message').find('.files-selected').append(`<p class="file-selected"> ${fileWithoutPath} <span class="remove-attachment-btn mdi mdi-window-close"></span> </p>`);
      } else {
        $(this).siblings('.files-selected').html(`<p class="file-selected"> ${fileWithoutPath} <span class="remove-attachment-btn mdi mdi-window-close"></span> </p>`);
      }
    });
  }

  $(document).on('click', '.remove-attachment-btn', function () {
    $(this).closest('p').remove();
  });
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
  $('a[disabled=disabled]').click(function(event){
    event.preventDefault();
  });

  $('#loginPrevBtn').click(function(event){
    event.preventDefault();
    history.back();
  });
})

$(function() {
  $('.chat-header__show-hide').click(function () {
    if ($(this).hasClass('conversation-hidden')) {
      $(this).find('p').html('Ukryj');
      $(this).removeClass('conversation-hidden');
      $(this).find('span').css('transform', 'rotate(0deg)');
      $(this).parent().siblings().slideToggle();
    } else {
      $(this).find('p').html('Pokaż');
      $(this).addClass('conversation-hidden');
      $(this).find('span').css('transform', 'rotate(180deg)');
      $(this).parent().siblings().slideToggle();
    }
  });

});

$(function() {
  $('.options__join').on('click', function() {
    joinBtn = $('.options__join')[0]
    $.ajax({
      type: "GET",
      url: "/count_offers/" + $(joinBtn).data('inquiry-id'),
      success(response){
        if (response.count >= 5) {
          $(".modal-body").html("Przepraszamy, nie możesz aktualnie dołączyć do przetargu, ponieważ zgłosiła się już maksymalna liczba agencji.");
        }
        if (response.ended == true) {
          $(".modal-body").html("Przepraszamy, wybrany przetarg został już zakończony!");
        }
      }
    });
  });
});

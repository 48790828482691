$(function() {

  let currentTab = 0; // Current tab is set to be the first tab (0)
  showTab(currentTab); // Display the crurrent tab
  const questionAnswer = []; // array for collecting questions answers

  $('.brief-draft-pdf').on('click', function(e) {
    var _href = $(this).attr("href");
    var formData = $('.brief-form').serialize();
    $(this).attr("href", _href + '?' + formData);
  })

  function updateStepCounter(currentStep, totalSteps) {
    currentStep += 1;
    $('.brief-window__step-counter').html(`Krok ${currentStep} z ${totalSteps+1}`);
  }

  var briefRadioButtons, d;

  briefRadioButtons = $('.question-choose--brief');
  $(briefRadioButtons).each(function (index, value) {
    $(value).find('.text-radio-input').on('click', function() {
      $(value).find('.text-radio').trigger('click');
    });
    $(value).find('.text-checkbox-input').on('click', function() {
      $(value).find('.text-checkbox').prop('checked', true);
    });
  });

  function showTab(n) {
    // This function will display the specified tab of the form...
    const x = $('.brief-step');
    $(x[n]).css('display', 'block');
    // length = x.length;
    length = $('.brief-window__step-counter').data('step-count')
    if ($('.brief-window__step-counter').data('max-step') == -1) {
      n = length;
    }
    if ($('.brief-window__step-counter').data('max-step') == 1) {
      n = length-1;
      $('#nextBtn').attr("disabled", !$('.brief-accept-terms input[type="checkbox"]').is(":checked"))
    }
    updateStepCounter(n, length);
    // ... and fix the Previous/Next buttons:
    if (n === 0) {
      $('#prevBtn').css('display', 'none');
    } else {
      $('#prevBtn').css('display', 'inline');
    }
    if (n === (x.length - 1) && length) {
      fillBriefPreview(questionAnswer);
      $('.brief-window').css('width', '100%');
      //$('#nextBtn').html('Zakończ');
    } else {
      //$('#nextBtn').html('Dalej').attr("disabled", false);
      $('.brief-window').css('width', '748px');
    }
  }

  function nextPrev(n) {
    // This function will figure out which tab to display
    const x = $('.brief-step');

    // Exit the function if any field in the current tab is invalid:
    if (n === 1 && !validateForm()) {
      return false;
    }

    // collectAnswers only when form is valid and button next is clicked (not for previous button)
    if (n === 1 && validateForm()) {
      if (currentTab < x.length - 1) {
        collectAnswer();
      }
    }

    removeErrorMarkers();

    $(x[currentTab]).css('display', 'none');
    currentTab += n;

    // if you have reached the end of the form
    if (currentTab >= x.length) {
      $('#brief-form').submit();
      return false;
    }
    showTab(currentTab);

    return true;
  }

  function validateForm() {
    let valid = true;
    const x = $('.brief-step');
    const radioButtons = $(x[currentTab]).find("input[type = 'radio']");
    const radioButtonsChecked = $(x[currentTab]).find("input[type = 'radio']:checked");
    const checkboxes = $(x[currentTab]).find("input[type = 'checkbox']");
    const textFields = $(x[currentTab]).find('.register-required-input');
    const checkboxesChecked = $(x[currentTab]).find('input[type = "checkbox"]:checked');
    const textDescription = $(x[currentTab]).find('.text-description');
    const briefName = $(x[currentTab]).find('.brief-title-input');
    const emailInput = $(x[currentTab]).find("input[type='email']");
    const passwordInput = $(x[currentTab]).find("#passwordInput");
    const passwordConfirmationInput = $(x[currentTab]).find("#passwordConfirmationInput");
    const lastPageCheckbox = $(x[currentTab]).find('.brief-accept-terms input[type=checkbox]');
    const phoneInput = $(x[currentTab]).find('.register-phone-input');

    // check if any radio button is checked
    if (radioButtons.length > 0) {
      if (radioButtonsChecked.length === 0) {
        for (let i = 0; i < radioButtons.length; i += 1) {
          $(radioButtons[i]).parents('.question-choose').addClass('brief-invalid-value');
        }
        valid = false;
      } else {
        for (let i = 0; i < radioButtonsChecked.length; i += 1) {
          // input text validate
          if ($(radioButtonsChecked[i]).siblings('.question-choose-label').find("input[type='text']").val() === '') {
            $(radioButtonsChecked[i]).parents('.question-choose').addClass('brief-invalid-value');
            valid = false;
          }
          // input date validate
          if ($(radioButtonsChecked[i]).siblings('.question-choose-label').find("input[type='date']").val() === '') {
            $(radioButtonsChecked[i]).parents('.question-choose').addClass('brief-invalid-value');
            valid = false;
          }
        }
      }
    }

    // text field validations on user registration
    for (let i = 0; i < textFields.length; i++) {
      // input text validate
      if ($(textFields[i]).val() === '') {
        $(textFields[i]).addClass('brief-invalid-value');
        valid = false;
      }
    }

    // registration phone number validation
    if (!(phoneInput.val() === '')) {
      const validNumber = "1234567890()-+ ";
      for(i=0;i<phoneInput.length;i++) {
        ch1=phoneInput.val().charAt(i);
        rtn1=validNumber.indexOf(ch1);
        if(rtn1==-1) {
          $(phoneInput).addClass('brief-invalid-value');
          valid = false;
        }
      }
    }

    // check if any checkbox is checked
    if (checkboxes.length > 0) {
      if (checkboxesChecked.length === 0) {
        for (let i = 0; i < checkboxes.length; i++) {
          $(checkboxes[i]).parents('.question-choose').addClass('brief-invalid-value');
        }
        valid = false;
      } else {
          for (let i = 0; i < checkboxesChecked.length; i++) {
          // input text validate
          if ($(checkboxesChecked[i]).next().find("input[type='text']").val() === '') {
            $(checkboxesChecked[i]).next().find("input[type='text']").addClass('brief-invalid-value');
            valid = false;
          }
          if ($(checkboxesChecked[i]).next().find("input[type='date']").val() === '') {
            $(checkboxesChecked[i]).next().find("input[type='date']").addClass('brief-invalid-value');
            valid = false;
          }
        }
      }
    }

    if ($(x[currentTab]).find('.question-choose').data('required') == true) {
      if (textDescription.length > 0) {
        if (textDescription.val() === '') {
          valid = false;

          $(textDescription).addClass('brief-invalid-value');
        }
      }
    }

    if (briefName.length > 0) {
      if (briefName.val() === '') {
        valid = false;
        $(briefName).addClass('brief-invalid-value');
      }
    }

    if (lastPageCheckbox.length > 0 && lastPageCheckbox !== null) {
      if (!lastPageCheckbox.is(":checked")) {
        $('.brief-accept-terms').addClass('brief-invalid-value');
        valid = false;
      }
    }

    // // check if password input is valid
    // if (passwordInput.length > 0) {
    //     console.log($(passwordInput).val().length < 6, $(passwordInput).val() ,$(passwordConfirmationInput).val());
    //   if ($(passwordInput).val() === '' || $(passwordInput).val().length < 6 || $(passwordInput).val() != $(passwordConfirmationInput).val()) {
    //       // if needed it could be checked by length and so on
    //       alert("A")
    //       console.log(($(passwordInput).val() === '',$(passwordInput).val().length < 6, $(passwordInput).val() != $(passwordConfirmationInput).val()));
    //     $(passwordInput).addClass('registration-form__invalid-input');
    //     valid = false;
    //   }
    // }

    // check if email value is valid
    if (emailInput.length > 0) {
      if (!validateEmail($(emailInput).val())) {
        $(emailInput).addClass('registration-form__invalid-input');
        valid = false;
      }
    }

    return valid; // return the valid status
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  $('#nextBtn').on('click', (e) => {
    e.preventDefault();
    nextPrev(1);
  });

  $('#prevBtn').on('click', () => {
    nextPrev(-1);
    $('#nextBtn').attr("disabled", false);
  });

  function removeErrorMarkers() {
    const errorMarkers = $('.brief-invalid-value');
    if (errorMarkers.length > 0) {
      for (let i = 0; i < errorMarkers.length; i += 1) {
        $(errorMarkers[i]).removeClass('brief-invalid-value');
      }
    }
  }

  function collectAnswer() {
    const x = $('.brief-step');
    const question = $(x[currentTab]).find('.brief-step-questions__title').html();
    const radioButtonElements = $(x[currentTab]).find('input[type=radio]:checked');
    const checkboxElements = $(x[currentTab]).find('input[type=checkbox]:checked');
    const textarea = $(x[currentTab]).find('.text-description');

    // this will prevent from collecting too much answers for one question
    // (when user go back and change his answer - old answer is removed)
    for (let i = 0; i < questionAnswer.length; i += 1) {
      if (questionAnswer[i].question === question) {
        questionAnswer.splice(i, 1);
        i -= 1;
      }
    }

    if (radioButtonElements.length > 0) {
      let answer = $(radioButtonElements).siblings('.question-answer').text();
      if (answer === '') {
        answer = $(radioButtonElements).siblings('.question-choose-label').find('.question-answer').val();
        if (answer === undefined) {
          answer = $('.question-answer-date').val();
        }
      }
      questionAnswer.push({
        question,
        answer
      });
    }

    if (checkboxElements.length > 0) {
      for (let i = 0; i < checkboxElements.length; i += 1) {
        let answer = $(checkboxElements[i]).parent().find('.question-answer').text();
        if (answer === '') {
          answer = $(checkboxElements[i]).parent().find('.question-answer').val();
        }
        questionAnswer.push({
          question,
          answer
        });
      }
    }

    if (textarea.length > 0) {
      if (textarea.val() !== '') {
        let answer = textarea.val().replace(/(\r\n|\n|\r)/gm, "<br>");
        questionAnswer.push({
          question,
          answer
        });
      }
    }
    // console.log(questionAnswer);
  }

  function fillBriefPreview(answerArray) {
    const questions = $('.preview-question');
    // if there is something on questions list remove it
    for (let i = 0; i < questions.length; i += 1) {
      if (!questions.hasClass('preview-question__edit')) {
        $(questions[i]).remove();
      }
    }

    // then print new questions there
    for (let i = 0; i < answerArray.length; i += 1) {
      if (answerArray[i].question != 'Podaj tytuł swojego zapytania') {
        const questionsList = $('.preview-questions-container');
        questionElement = `<p class="preview-question__question">${answerArray[i].question}</p>`;
        if(i > 0 && answerArray[i].question == answerArray[(i-1)].question){
          questionElement = '';
        }
        answerElement = `<p class="preview-question__answer list-dot">${answerArray[i].answer}</p>`;
        $(questionsList).append('<div class="preview-question"></div>');
        const questionContainer = $('.preview-question');
        $(questionContainer[i]).append([questionElement, answerElement]);
      } else {
        $('.brief-content__title').html(answerArray[i].answer)
      }
    }
  }
  const x = $('.brief-step');
  const userInput = $('input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea');

  for (let i = 0; i < userInput.length; i += 1) {
    $(userInput[i]).on('input', function () {
      $(this).removeClass('brief-invalid-value');
    });
  }
  $('.brief-accept-terms input[type="checkbox"]').on('change', function() {
    if ($('.brief-accept-terms input[type="checkbox"]').is(":checked")) {
        $('.brief-accept-terms').removeClass('brief-invalid-value');
        $('#nextBtn').attr("disabled", false);
    } else {
        $('#nextBtn').attr("disabled", true);
    }
  });
  $('input[type=radio][name=product]').change(function() {
    $('#product-form').submit();
  });
});

$(function() {
  let currentTab = 0; // Current tab is set to be the first tab (0)
  showTab(currentTab); // Display the crurrent tab

  function updateStepCounter(currentStep, totalSteps) {
    currentStep += 1;
    $('.registration-window__step-counter').html(`Krok ${currentStep} z ${totalSteps}`);
  }

  function showTab(n) {
    // This function will display the specified tab of the form...
    const x = $('.registration-step');
    $(x[n]).css('display', 'block');
    updateStepCounter(n, x.length);
    // ... and fix the Previous/Next buttons:
    $('#submitBtn').css('display', 'none');

    if (n === 0) {
      $('#prevBtn').css('display', 'none');
    } else {
      $('#prevBtn').css('display', 'inline');
    }
    if (n === (x.length - 1)) {
      $('#nextBtn').css('display', 'none');
      $('#submitBtn').css('display', 'inline');
    } else {
      $('#nextBtn').css('display', 'inline');
    }
  }

  function nextPrev(n) {
    // This function will figure out which tab to display
    const x = $('.registration-step');

    // Exit the function if any field in the current tab is invalid:
    if (n === 1 && !validateForm()) {
      console.log('failed');
      return false;
    }
    // Hide the current tab:
    $(x[currentTab]).css('display', 'none');
    currentTab += n;

    // if you have reached the end of the form...
    if (currentTab >= x.length) {
      $('#agency-registration-form').submit();
      return false;
    }
    showTab(currentTab);

    return true;
  }

  function validateForm() {
    // This function deals with validation of the form fields
    let valid = true;
    const x = $('.registration-step');
    const textInput = $(x[currentTab]).find("input[type = 'text'].required-input:visible");
    const passwordInput = $(x[currentTab]).find("input[type='password'].required-input");
    const passwordInputIcon = $(x[currentTab]).find('.password-input-container__icon');
    const checkboxes = $(x[currentTab]).find("input[type = 'checkbox']");
    const checkboxesChecked = $(x[currentTab]).find('input[type = "checkbox"]:checked');
    const emailInput = $(x[currentTab]).find("input[type='email'].required-input");
    const telephoneInput = $(x[currentTab]).find("input[type='tel'].required-input");
    const textDescription = $(x[currentTab]).find('textarea.required-input');
    const radioButtons = $(x[currentTab]).find('.registration-question');
    const lastPageCheckbox = $(x[currentTab]).find('.brief-accept-terms input[type=checkbox]');
    // const radioButtonsChecked = $(radioButtons).find('input[type = "radio"]:checked');

    if (radioButtons.length > 0) {
      for (let i = 0; i < radioButtons.length; i += 1) {
        let radioButtonsChecked = $(radioButtons[i]).find('.question-choose').find('input[type = "radio"]:checked');
        if (radioButtonsChecked.length === 0) {
          valid = false;
        }
      }
    }

    // check if any checkbox is checked
    if (checkboxes.length > 0) {
      if (checkboxesChecked.length === 0) {
        for (let i = 0; i < checkboxes.length; i++) {
          $(checkboxes[i]).parents('.label').parents('.question-choose').addClass('brief-invalid-value');
        }
        valid = false;
      } else {
        for (let i = 0; i < checkboxesChecked.length; i++) {
          // input text validate
          if ($(checkboxesChecked[i]).siblings("input[type='text']").val() === '') {
            $(checkboxesChecked[i]).siblings("input[type='text']").addClass('brief-invalid-value');
            valid = false;
          }
        }
      }
    }

    // loop that checks every text input field in the current tab:
    if (textInput.length > 0) {
      for (let i = 0; i < textInput.length; i += 1) {
        // If a field is empty...
        if ($(textInput[i]).val() === '') {
          $(textInput[i]).addClass('registration-form__invalid-input');
          valid = false;
        }
      }
    }

    // check if password input is valid
    if (passwordInput.length > 0) {
      if ($(passwordInput).val() === '' || $(passwordInput).val().length < 6) {
        // if needed it could be checked by length and so on
        $(passwordInput).addClass('registration-form__invalid-input');
        $(passwordInputIcon).addClass('registration-form__invalid-input');
        valid = false;
      }
    }

    // check if email value is valid
    if (emailInput.length > 0) {
      if (!validateEmail($(emailInput).val())) {
        $(emailInput).addClass('registration-form__invalid-input');
        valid = false;
      }
    }
    // check if telephone is valid
    if (telephoneInput.length > 0) {
      if ($(telephoneInput).val() === '') {
        $(telephoneInput).addClass('registration-form__invalid-input');
        valid = false;
      }
    }

    if (lastPageCheckbox.length > 0 && lastPageCheckbox !== null) {
      if (!lastPageCheckbox.is(":checked")) {
        $('.brief-accept-terms').addClass('brief-invalid-value');
        valid = false;
      }
    }

    //
    // check if company description is valid
    if (textDescription.length > 0) {
      if ($(textDescription).val() === '') {
        $(textDescription[0]).addClass('registration-form__invalid-input');
        valid = false;
      }
    }

    return valid; // return the valid status
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  $('#nextBtn').on('click', () => {
    nextPrev(1);
  });

  $('#submitBtn').on('click', () => {
    nextPrev(1);
  });

  $('#prevBtn').on('click', () => {
    nextPrev(-1);
    $('#nextBtn').attr("disabled", false);
  });

  // remove red border after retyping input (red border occurs if there is invalid value)
  const userInput = $('input[type="text"], input[type="password"], input[type="email"], input[type="tel"], textarea');
  for (let i = 0; i < userInput.length; i += 1) {
    $(userInput[i]).on('input', function () {
      $(this).removeClass('registration-form__invalid-input');
    });
  }
  $('.brief-accept-terms input[type="checkbox"]').on('change', function() {
    if ($('.brief-accept-terms input[type="checkbox"]').is(":checked")) {
        $('.brief-accept-terms').removeClass('brief-invalid-value');
        $('#submitBtn').attr("disabled", false);
    }
    else {
        $('#submitBtn').attr("disabled", true);
    }
  });
});

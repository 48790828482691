$(function() {

  $('.offerButton').on('click', (e) => {
    e.preventDefault();
    $wrapper = $(e.target).parents('.modal')
    submitForm($wrapper);
  });

  function submitForm($wrapper) {
    removeErrorMarkers();
    if (!validateForm($wrapper)) {
      return false;
    }
    if (validateForm($wrapper)) {
      $wrapper.find('.offerForm').submit();
      return false;
    }
  }

  function removeErrorMarkers() {
    const errorMarkers = $('.brief-invalid-value');
    if (errorMarkers.length > 0) {
      for (let i = 0; i < errorMarkers.length; i += 1) {
        $(errorMarkers[i]).removeClass('brief-invalid-value');
      }
    }
  }

  function validateForm($wrapper) {
    let valid = true;
    const numberFields = $wrapper.find("input[type='number']");
    const fileField = $wrapper.find("input[type='file']");

    for (let i = 0; i < numberFields.length; i++) {
      if ($(numberFields[i]).val() === '') {
        $(numberFields[i]).addClass('brief-invalid-value');
        valid = false;
      }
    }
    if ($(fileField).val() == '') {
      $(fileField).siblings('label').find('.mdi').addClass('brief-invalid-value');
      valid = false;
    }

    return valid;
  }
});

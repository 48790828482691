$(function() {
  const showHide = $('#showHidePass');
  const passwordInput = $('#passwordInput');
  let currentType;

  if (showHide.length > 0) {
    $(showHide[0]).on('click', () => {
      currentType = $(passwordInput[0]).attr('type');
      if (currentType === 'password') {
        $(passwordInput[0]).attr('type', 'text');
      } else {
        $(passwordInput[0]).attr('type', 'password');
      }
    });
  }
});

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

var allowCookies, cookiesAllowed, ga;

cookiesAllowed = null;

ga =
  "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" +
  "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," +
  "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" +
  "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" +
  "ga('create', 'UA-X-X', 'auto');" + "ga('send', 'pageview');";

allowCookies = function() {
  setCookie('allow_cookies', 'yes', 365);
  $('head').append('<script>' + ga + '</script>');
  cookiesAllowed = 'yes';
  $('#cookies-bar').fadeOut();
};

const ready = function() {
  const cookiesAllowed = getCookie('allow_cookies');

  $('a.allow-cookies').on('click', function(e) {
    if (cookiesAllowed !== 'yes') { return allowCookies(); }
  });

  // return $(document).scroll(function() {
  //   if (cookiesAllowed !== 'yes') { return allowCookies(); }
  // });
};

$(document).ready(ready);
$(document).on('page:load', ready);

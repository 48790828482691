$(function() {
  function validateStartButton() {
    let valid = false;
    const radioButtonsChecked = $('.choose-section').find("input[type = 'radio']:checked");
    //const checkboxesChecked = $('.choose-section').find('input[type = "checkbox"]:checked');
    const startButton = $('.start-button');

    $(startButton[0]).attr("disabled", !(radioButtonsChecked.length == 1))
    if(radioButtonsChecked.length == 1) {
      $(startButton[0]).css('pointer-events', 'auto');
    }
  }

  let radioButtons = $('.choose-section').find("input[type = 'radio']");
  //let checkboxes = $('.choose-section').find('input[type = "checkbox"]');

  if (radioButtons.length > 0) {
    for (let i = 0; i < radioButtons.length; i += 1) {
      $(radioButtons[i]).on("click", function(){
        validateStartButton();
      })
    }
  }

  // if (checkboxes.length > 0) {
  //   for (let i = 0; i < checkboxes.length; i += 1) {
  //     $(checkboxes[i]).on('click', function() {
  //       validateStartButton();
  //     });
  //   }
  // }
});

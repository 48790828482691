import 'jquery'
import 'jquery-ujs'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'bootstrap'
import 'password-strength-meter'
import '@mdi/font/scss/materialdesignicons.scss'

import '../stylesheets/application.scss'

import '../javascript/display-attachment-name.js'
import '../javascript/nested_form.js'
import '../javascript/comments-slide.js'
import '../javascript/registration-show-hide-password.js'
import '../javascript/registration-validation.js'
import '../javascript/brief-validation.js'
import '../javascript/start-button-validation.js'
import '../javascript/animate-scroll.js'
import '../javascript/inquiry-templates-form.js'
import '../javascript/cookies.js'
import '../javascript/check-offers.js'
import '../javascript/inquiry-offer-validation.js'
import '../javascript/ganalytics.js'
import '../javascript/buttons.js'

const images = require.context('../images/', true)

$(function() {
  $('#passwordInput').password({
    shortPass: 'Hasło zbyt krótkie',
    badPass: 'Słabe; wypróbuj kombinację cyfr i liter',
    goodPass: 'Średnie; wybróbuj znaki specjalne',
    strongPass: 'Silne Hasło!',
    containsUsername: 'Hasło zawiera nazwę użytkownika',
    enterPass: 'Wprowadź hasło',
    showPercent: false,
    showText: true, // shows the text tips
    animate: true, // whether or not to animate the progress bar on input blur/focus
    animateSpeed: 'fast', // the above animation speed
    username: false, // select the username field (selector or jQuery instance) for better password checks
    usernamePartialMatch: true, // whether to check for username partials
    minimumLength: 6 // minimum password length (below this threshold, the score is 0)
  });

  $('form').submit(function() {
      $('#overlay').fadeIn()
  })


})
